<script>
import Modal from '@/components/Modal'
import LibraryList from '@/components/LibraryList'

export default {
  components: {
    Modal,
    LibraryList
  },
  props: {
    photo: {
      type: Object,
      default: () => {},
    },
    label: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isOpened: false,
      selectedNewPhoto: null,
      displayedPhoto: null
    }
  },
  watch: {
		photo() {
			this.displayedPhoto = this.photo
		},
  },
  mounted() {
    this.displayedPhoto = this.photo
  },
  methods: {
    removePhoto() {
			this.photo = null
			this.updateLibraryId(null)
		},
		updatePhoto(items) {
			this.selectedNewPhoto = items[0]
		},
		confirmNewPhoto() {
			if(this.selectedNewPhoto) {
        this.displayedPhoto = this.selectedNewPhoto
        this.$emit('updatePhoto', this.displayedPhoto)
        this.selectedNewPhoto = null
      }
			this.isOpened = false
		},
  }

}
</script>

<template>
  <div>
    <div class="photo">
      <label>{{ label }}</label>
      <div
        v-if="displayedPhoto"
        class="photoContainer"
      >
        <BaseImage
          :source="displayedPhoto"
          size="thumb"
        />
        <div class="photoOverlay text-center">
          <div>
            <BaseIcon
              icons-group="far"
              icon="fa-edit"
              class="icon iconHover iconEdit"
              size="l"
              @click="isOpened = true"
            />
            <div>{{ $t('edit') }}</div>
          </div>
          <div>
            <BaseIcon
              icons-group="fas"
              icon="fa-ban"
              class="icon iconHover iconDelete"
              size="l"
              @click="removePhoto"
            />
            <div>{{ $t('remove') }}</div>
          </div>
        </div>
      </div>
      <div
        v-else
        class="photoContainer"
      >
        <div class="photoOverlay">
          <div class="text-center">
            <BaseIcon
              icons-group="fas"
              icon="fa-plus-circle"
              class="icon iconHover iconAdd"
              size="xl"
              @click="isOpened = true"
            />
            <div>{{ $t('addItem', { item: $tc('photo') }) }}</div>
          </div>
        </div>
        <div class="photoAlt">
          <BaseIcon
            icons-group="far"
            icon="fa-image"
            class="icon iconImage"
            size="xxxl"
            fill
          />
        </div>
      </div>
    </div>
    <Modal
      :show.sync="isOpened"
      :centered="true"
      :show-close="true"
    >
      <LibraryList
        v-if="isOpened"
        :limit="40"
        select-items="single"
        class="library"
        :preselected-items="displayedPhoto ? [displayedPhoto] : []"
        fixed-type="photos"
        grid-item-size="100"
        @updateSelected="updatePhoto"
      />
      <div class="libraryButton text-right">
        <BaseButton
          type="green"
          gradient
          @click="confirmNewPhoto"
        >
          {{ $t('confirm') }}
        </BaseButton>
      </div>
    </Modal>
  </div>
</template>

<style lang="sass" scoped>
@import '~@/assets/sass/design.sass'

.photo
  position: relative
  &Alt
    text-align: center
  &Container
    position: relative
    padding: $base-spacing
  &Button
    margin-top: $base-spacing
  &Overlay
    display: flex
    align-items: center
    justify-content: space-around
    width: 100%
    height: 100%
    color: $c-white
    opacity: 0
    transition: opacity .3s ease-in-out
    @include center
    &::before
      z-index: -1
      width: 100%
      height: 100%
      content: ''
      background: $green-blue-purple-gradient
      border-radius: 8px
      opacity: 0.8
      @include center
  &:hover &Overlay
    opacity: 1
.library
  max-height: 85vh
  overflow: auto
  &Button
    padding: $base-spacing
.icon
  &Add
    color: $c-acc-green
  &Delete
    color: $c-acc-red
  &Edit
    color: $c-san-juan-blue
  &Hover
    cursor: pointer
</style>
