import { post } from '@/services/api.service';

const API_URL = '/migrate-db';

class MigrationService {
  migrateProdDbToDev() {
    return post(`${API_URL}`)
    .then(res => res)
  }
}

export default new MigrationService();