/* eslint-disable camelcase */
export default class Settings {
  email;
  library_id;
  logo_id;
  name;
  tagline;

  mini_width;
  mini_compression;
  mini_height;
  thumb_width;
  thumb_height;
  thumb_compression;
  md_width;
  md_height;
  md_compression;
  slider_width;
  slider_height;
  slider_compression;

  constructor() {
    this.email = "";
    this.library_id = null;
    this.logo_id = null;
    this.name = "Game Medium";
    this.tagline = "Everything about gaming Consoles";

    this.mini_width = 75;
    this.mini_height = 75;
    this.mini_compression = 90;
    this.thumb_width = 5000;
    this.thumb_height = 200;
    this.thumb_compression = 90;
    this.md_width = 5000;
    this.md_height = 500;
    this.md_compression = 85;
    this.slider_width = 1920;
    this.slider_height = 5000;
    this.slider_compression = 80;
  }
}