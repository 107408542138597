<script>
import settingsService from '@/services/settings.service'
import migrationService from '@/services/migration.service'
import Settings from '@/data/models/Settings'
import EditLayout from '@/layout/EditLayout'
import libraryService from '@/services/library.service'
import LibraryModal from '@/components/LibraryModal'

export default {
  components: {
    EditLayout,
    LibraryModal
  },
	data() {
		return {
      item: new Settings,
      logo: null,
      background: null,
		}
  },
	mounted() {
    this._get()
	},
	methods: {
    _get() {
      settingsService.get()
        .then(res => {
          this.item = res.data
          res.data.logo_id && this._getLogo({ id: res.data.logo_id, model: this.logo })
          res.data.library_id && this._getBackground({ id: res.data.library_id, model: this.background })
        })
    },
    edit() {
      settingsService.edit({ data: this.item })
        .then(_res => {
          this.$notify({
            message: this.$t('m.itemSuccessfullySaved', { item: this.$tc('setting', 2) }),
            icon: "far fa-bell",
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success',
          })
        })
    },
    _getLogo() {
      libraryService.getById({ id: this.item.logo_id })
        .then(res => {
          this.logo = res.data
        })
    },
    _getBackground() {
      libraryService.getById({ id: this.item.library_id })
        .then(res => {
          this.background = res.data
        })
    },
    updatePhoto(newVal) {
      this.logo = newVal
      this.item.logo_id = newVal.id
    },
    migrateProdDbToDev() {
      migrationService.migrateProdDbToDev()
        .then(_res => {
          this.$notify({
            message: this.$t('m.prodDBHasSuccessfullyBeenMigratedToDev'),
            icon: "far fa-bell",
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success',
          })
        })
    }
	}
}
</script>

<template>
  <div>
    <EditLayout
      :item="item"
      :submit-button-text="$t('save')"
      :supports-editor="false"
      :photo="background"
      supports-photo
      @submit="edit()"
    >
      <div slot="middle">
        <section>
          <h4>{{ $t('generalSettings') }}</h4>
          <div>
            <BaseInput
              v-model="item.name"
              :label="$t('sitesTitle')"
              :placeholder="$t('enterSitesTitle')"
            />
          </div>
          <div>
            <BaseInput
              v-model="item.tagline"
              :label="$t('sitesSubtitle')"
              :placeholder="$t('enterSitesSubtitle')"
            />
          </div>
          <div>
            <BaseInput
              v-model="item.email"
              :label="$t('systemEmail')"
              :placeholder="$t('enterSystemEmail')"
              type="email"
            />
          </div>
        </section>
        <section>
          <h4>{{ $t('imageSettings') }}</h4>
          <div>
            <h5>{{ $t('miniPhotos') }}</h5>
            <div class="row">
              <BaseInput
                v-model="item.mini_width"
                class="col-sm-4"
                type="number"
                :label="$t('width')"
                :placeholder="$t('width')"
              />
              <BaseInput
                v-model="item.mini_height"
                class="col-sm-4"
                type="number"
                :label="$t('height')"
                :placeholder="$t('height')"
              />
              <BaseInput
                v-model="item.mini_compression"
                class="col-sm-4"
                type="number"
                :label="$t('compression')"
                :placeholder="$t('setAValueBetween', { min: 0, max: 100 })"
                min="0"
                max="100"
              />
            </div>
          </div>
          <div>
            <h5>{{ $t('thumbPhotos') }}</h5>
            <div class="row">
              <BaseInput
                v-model="item.thumb_width"
                class="col-sm-4"
                type="number"
                :label="$t('width')"
                :placeholder="$t('width')"
              />
              <BaseInput
                v-model="item.thumb_height"
                class="col-sm-4"
                type="number"
                :label="$t('height')"
                :placeholder="$t('height')"
              />
              <BaseInput
                v-model="item.thumb_compression"
                class="col-sm-4"
                type="number"
                :label="$t('compression')"
                :placeholder="$t('setAValueBetween', { min: 0, max: 100 })"
                min="0"
                max="100"
              />
            </div>
          </div>
          <div>
            <h5>{{ $t('mediumPhotos') }}</h5>
            <div class="row">
              <BaseInput
                v-model="item.md_width"
                class="col-sm-4"
                type="number"
                :label="$t('width')"
                :placeholder="$t('width')"
              />
              <BaseInput
                v-model="item.md_height"
                class="col-sm-4"
                type="number"
                :label="$t('height')"
                :placeholder="$t('height')"
              />
              <BaseInput
                v-model="item.md_compression"
                class="col-sm-4"
                type="number"
                :label="$t('compression')"
                :placeholder="$t('setAValueBetween', { min: 0, max: 100 })"
                min="0"
                max="100"
              />
            </div>
          </div>
          <div>
            <h5>{{ $t('sliderLargePhotos') }}</h5>
            <div class="row">
              <BaseInput
                v-model="item.slider_width"
                class="col-sm-4"
                type="number"
                :label="$t('width')"
                :placeholder="$t('width')"
              />
              <BaseInput
                v-model="item.slider_height"
                class="col-sm-4"
                type="number"
                :label="$t('height')"
                :placeholder="$t('height')"
              />
              <BaseInput
                v-model="item.slider_compression"
                class="col-sm-4"
                type="number"
                :label="$t('compression')"
                :placeholder="$t('setAValueBetween', { min: 0, max: 100 })"
                min="0"
                max="100"
              />
            </div>
          </div>
        </section>
        <section>
          <BaseButton @click="migrateProdDbToDev">
            {{ $t('migrateProdDBToDev') }}
          </BaseButton>
        </section>
      </div>
      <div slot="sidebar-middle">
        <section>
          <LibraryModal
            :photo="logo"
            :label="$t('logo')"
            @updatePhoto="updatePhoto"
          />
        </section>
      </div>
    </EditLayout>
  </div>
</template>

<style lang="sass" scoped>
@import '~@/assets/sass/design.sass'

</style>
